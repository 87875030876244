import React, { useState } from "react";
import axios from "axios";
import SuccessModal from "../components/SuccessModal";
import {
  validateEmail,
  validateMessage,
  validateName,
} from "../utils/ValidateInput";
import { API_ENDPOINTS } from "../environment/api_endpoints";

const ContactPage: React.FC = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Reset errors
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    let isValid = true;

    // Validate name
    if (!validateName(name)) {
      setNameError(true);
      isValid = false;
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError(true);
      isValid = false;
    }

    // Validate message
    if (!validateMessage(message)) {
      setMessageError(true);
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
      return;
    } else {
      setLoading(true);
    }

    // If all is valid, send the form data
    try {
      const response = await axios.post(API_ENDPOINTS.SEND_EMAIL, {
        name,
        email,
        message,
      });
      console.log("Message sent:", response.data);
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle server error response here
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (text: string) => {
    setName(text);
  };

  const handleEmailChange = (text: string) => {
    setEmail(text);
  };

  const handleMessageChange = (text: string) => {
    setMessage(text);
  };

  const onClose = () => {
    setIsSuccessModalOpen(false);
  };

  return (
    <>
      <div className="bg-black p-10">
        <div className="">
          <h1 className="text-3xl font-bold text-white pb-4">Get in Touch</h1>
          <div className="flex flex-col md:flex-row md:space-x-10">
            <div className="w-full md:w-1/2">
              {/* <h2 className="text-2xl font-bold mb-4 text-white">
                Contact Form
              </h2> */}
              <form onSubmit={handleSubmit}>
                {/* Name input */}
                <div className="mb-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-bold mb-2 text-white"
                  >
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={(e) => handleNameChange(e.target.value)}
                    placeholder="Enter your name"
                    className="w-full p-2 border rounded bg-white"
                  />
                  {nameError && (
                    <p className="font-bold text-red-500">
                      Ensure you entered a valid name
                    </p>
                  )}
                </div>

                {/* Email input */}
                <div className="mb-4">
                  <label
                    htmlFor="email"
                    className="block text-sm font-bold mb-2 text-white"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => handleEmailChange(e.target.value)}
                    placeholder="Enter your email"
                    className="w-full p-2 border rounded bg-white"
                  />
                  {emailError && (
                    <p className="font-bold text-red-500">
                      Ensure you entered a valid email
                    </p>
                  )}
                </div>

                {/* Message textarea */}
                <div className="mb-4">
                  <label
                    htmlFor="message"
                    className="block text-sm font-bold mb-2 text-white"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={(e) => handleMessageChange(e.target.value)}
                    placeholder="Enter your message"
                    rows={4}
                    className="w-full p-2 border rounded bg-white min-h-72"
                  ></textarea>
                  {messageError && (
                    <p className="font-bold text-red-500">
                      Ensure you entered a valid message than is at least 50
                      characters long
                    </p>
                  )}
                </div>

                {/* Submit button */}
                <button
                  type="submit"
                  className="bg-white text-black hover:bg-[#ff5c01] hover:text-white px-4 py-2 rounded font-bold"
                >
                  {loading ? "Sending..." : "Send Message"}
                </button>
              </form>
            </div>

            {/* <div className="w-full md:w-1/2 mt-10 md:mt-0 text-white">
              <h2 className="text-2xl font-bold mb-4">Contact Information</h2>
              <p className="mb-2">Email: contact@example.com</p>
              <p className="mb-2">Phone: 123-456-7890</p>
            </div> */}
          </div>
        </div>
        {isSuccessModalOpen && (
          <SuccessModal isOpen={isSuccessModalOpen} onClose={() => onClose()} />
        )}
      </div>
    </>
  );
};

export default ContactPage;
