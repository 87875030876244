import React from "react";

type Props = {};

const CopyrightPage = (props: Props) => {
  return (
    <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff]">
      <div className="px-20 pb-10">
        <h1 className="text-3xl font-bold mb-6">Copyright</h1>

        <p>
          The content, materials, and intellectual property displayed on our
          website or provided as part of our services, including but not limited
          to text, graphics, logos, images, and software, are owned by
          TechTangelo or its licensors and are protected by copyright,
          trademark, and other laws. You may not reproduce, distribute, modify,
          or create derivative works of any content without our prior written
          consent.
        </p>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">1. Use of Content</h2>
          <p>
            You may access and view the content on our website for your personal
            and non-commercial use only. Any other use of the content, including
            but not limited to reproduction, distribution, or modification, is
            strictly prohibited without our prior written consent.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">2. Trademarks</h2>
          <p>
            TechTangelo's name and logo are trademarks owned by TechTangelo
            (also known as techtangelo, TechTangelo, or TECHTANGELO, or any
            other alphanumeric variation of the name). You may not use our
            trademarks without our prior written permission. Other trademarks,
            logos, and service marks displayed on our website are the property
            of their respective owners.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">3. Enforcement</h2>
          <p>
            We reserve the right to enforce our intellectual property rights to
            the fullest extent permitted by law. Any unauthorized use of our
            content or trademarks may result in legal action.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">4. Contact Us</h2>
          <p>
            If you have any questions or concerns about copyright infringement
            or our intellectual property rights, please contact us at{" "}
            <a href="mailto:copyright@techtangelo.com">
              copyright@techtangelo.com
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default CopyrightPage;
