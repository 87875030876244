import React from "react";

const SuccessModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  if (!isOpen) return null;

  return (
    <div
      className="absolute inset-0 bg-white bg-opacity-70 overflow-hidden h-full w-full z-50 flex items-center justify-center"
      onClick={onClose}
    >
      <div
        className="bg-black p-5 w-5/6 md:w-[600px] shadow-lg rounded-md"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-row justify-between items-center">
          <p className="text-2xl font-bold mb-4 text-white">Success!</p>
          <button
            className="bg-white h-8 w-8 absolute top-2 right-2 text-2xl font-bold hover:text-gray-500 rounded-md flex justify-center items-center"
            onClick={onClose}
          >
            <p className="pb-1">&times;</p>
          </button>
        </div>
        <h2 className="text-xl font-bold mb-4 text-white">
          Thank you for reaching out!
        </h2>
        <p className="text-md mb-4 text-white">
          Your message has been successfully sent. We will get back to you soon.
        </p>
        <button
          type="button"
          className="bg-white hover:bg-[#ff5c01] text-black hover:text-white hover:scale-110 focus:scale-90 transition ease-in-out px-4 py-2 rounded font-bold justify-center"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessModal;
