import React, { useEffect, useState } from "react";
import ContactModal from "../components/ContactModal";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
// Alternatively:
// import Lottie from 'react-lottie-player/dist/LottiePlayerLight'

import ServiceItem from "../components/ServiceItem";

import BannerAnimation from "../animations/BannerAnimation";

import lottieJson from "../myLottie2.json";

const HomePage = () => {
  const [isContactModalOpen, setContactModalOpen] = React.useState(false);

  const animatedSegments = [
    { text: "The Developers You ", isStyled: false },
    { text: "Deserve", isStyled: true },
    { text: ". ", isStyled: false },
    { text: "Crafting ", isStyled: false },
    { text: "Excellence", isStyled: true },
    { text: " Every Step of the Way.", isStyled: false },
  ];

  const images = [
    "/stockImage1.jpg",
    "/stockImage2.jpg",
    "/stockImage3.jpg",
    "/stockImage4.jpg",
    "/stockImage5.jpg",
    "/stockImage6.jpg",
    "/stockImage7.jpg",
    "/stockImage1.jpg",
    "/stockImage2.jpg",
    "/stockImage3.jpg",
    "/stockImage4.jpg",
    "/stockImage5.jpg",
    "/stockImage6.jpg",
    "/stockImage7.jpg",
    "/stockImage1.jpg",
    "/stockImage2.jpg",
    "/stockImage3.jpg",
    "/stockImage4.jpg",
  ];

  const Slider = () => {
    const [slideVal, setSlideVal] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (slideVal < 1500) {
          setSlideVal(slideVal + 1);
        } else {
          setSlideVal(0);
        }
      }, 20);

      return () => clearInterval(interval);
    }, [slideVal]);

    return (
      <div className="overflow-hidden flex flex-row space-x-1">
        {/* <div className="h-[512px] w-[512px]"> */}
        {images.map((image, i) => (
          <img
            key={i}
            src={image}
            style={{ transform: `translateX(-${slideVal}px)` }}
            className=" h-[250px] w-[250px] object-cover"
          />
        ))}
        {/* </div> */}
      </div>
    );
  };

  const windowWidth = window.innerWidth;

  return (
    <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff] overflow-x-hidden">
      <div className="md:px-20 lg:px-40 py-12 grid lg:grid-cols-2 grid-cols-1 items-center lg:-mt-10">
        <div className="flex flex-col lg:space-y-5 space-y-8 items-center justify-center w-full">

          <div className="lg:hidden -mt-10">
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width: "100%", height: 250 }}
            />
          </div>

          <div className="md:px-0 px-5 flex justify-start items-start w-full min-h-[130px] md:min-h-[190px]">
            <BannerAnimation
              segments={animatedSegments}
              className="md:text-5xl text-3xl font-bold lg:w-full w-full"
            />
          </div>

          <div className="flex flex-row w-full space-x-5 md:justify-start justify-center md:pb-0 pb-10">
            <button className="bg-black hover:bg-[#ff5c01] hover:scale-110 active:scale-90 transition ease-in-out text-white font-bold py-2 px-4 rounded hover:text-black md:text-lg text-xl">
              <a href="/portfolio">See our Work</a>
            </button>
            <button className="bg-black hover:bg-[#ff5c01] hover:scale-110 active:scale-90 transition ease-in-out text-white font-bold py-2 px-4 rounded hover:text-black md:text-lg text-xl">
              <div className="flex flex-row space-x-2 items-center">
                <a href="/contact">Contact Us</a>
                <svg
                  version="1.1"
                  id="Uploaded to svgrepo.com"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20px"
                  height="20px"
                  viewBox="0 0 32 32"
                  fill="#ffffff"
                >
                  <path
                    d="M30.291,2.87l-9.581,25.26c-0.39,1.029-1.346,1.234-2.123,0.456c0,0-6.036-6.036-6.586-6.586
	s-0.359-1.631,0.425-2.403l13.316-13.11c0.784-0.772,0.711-0.856-0.163-0.187L10.588,17.784c-0.873,0.669-2.224,0.58-3.002-0.198
	l-4.172-4.172c-0.778-0.778-0.573-1.733,0.456-2.124l25.26-9.581C30.159,1.319,30.681,1.841,30.291,2.87z M8.707,20.121
	C8.318,19.732,8,19.864,8,20.414V25c0,0.55,0.386,0.768,0.857,0.485l2.401-1.441c0.472-0.283,0.539-0.833,0.15-1.222L8.707,20.121z"
                  />
                </svg>
              </div>
            </button>
            {/* <button>Contact Us</button> */}

            {/* <img src="/gridbg.svg" alt="" /> */}
          </div>

          <p className="md:block hidden font-light text-xl md:w-full w-4/5 text-left md:px-0 px-5">
            Accelerate & Empower Your Online Presence with Tailored Solutions
            and Expertise.
          </p>
        </div>

        <div className="h-full -mt-0 lg:flex lg:flex-col md:items-center hidden ">
          <Lottie
            loop
            animationData={lottieJson}
            play
            style={{ width: windowWidth * 0.35, height: "100%" }}
          />
        </div>
      </div>

      <div className="bg-black md:px-0 px-5">
        {/* <Slider /> */}
        <div className="flex flex-col justify-center items-center py-10">
          <h1 className="text-white md:text-5xl text-3xl font-semibold pb-10 md:px-10 px-2">
            Delivering{" "}
            <span className="underline decoration-[#ff5c01]">
              high performing
            </span>{" "}
            & <span className="underline decoration-[#ff5c01]">optimized</span>{" "}
            software solutions.
          </h1>
          <div className="grid md:grid-cols-3 md:grid-rows-2 grid-cols-2 grid-rows-3 justify-center gap-5 md:px-10">
            <ServiceItem text="Websites" />
            <ServiceItem text="Web Applications" />
            <ServiceItem text="Mobile Applications" />
            <ServiceItem text="Software Maintenance" />
            <ServiceItem text="Customer Support" />
            <ServiceItem text="UI/UX Designs" />
          </div>
          <div className="py-10">
            <Slider />
          </div>
          <p className="text-white text-xl font-light  text-center">
            Revolutionize your business with our development expertise
          </p>

          <div className="grid grid-cols-2 justify-center gap-5 py-2">
            <a
              href="/contact"
              className="bg-[#ff5c01] hover:scale-110 transition ease-in-out hover:text-white border-[#ff5c01] text-white font-bold py-2 px-4 rounded-md text-center"
            >
              Get in Touch
            </a>
            <a
              href="/services"
              className="bg-[#ff5c01] hover:scale-110 transition ease-in-out hover:text-white  border-[#ff5c01] text-white font-bold py-2 px-4 rounded-md text-center"
            >
              View Our Work
            </a>
          </div>
        </div>
      </div>

      <ContactModal
        isOpen={isContactModalOpen}
        onClose={() => setContactModalOpen(false)}
      />

      {/* Other Sections... */}
    </div>
  );
};

export default HomePage;
