// ServicesPage.tsx

import React, { ReactNode } from "react";

const ServicesPage: React.FC = () => {
  const ProcessStep = ({
    title,
    text,
    icon,
  }: {
    title: string;
    text: string;
    icon: ReactNode;
  }) => {
    return (
      <>
        <div className="bg-gray-200 rounded-lg p-4 shadow-md grid grid-cols-6">
          <div className="grid grid-rows-2 col-span-5 b-slate-500">
            <h5 className="text-lg font-bold col-span-4 b-red-400">{title}</h5>
            <p className="font-light b-gray-700">{text}</p>
          </div>
          <div className="grid grid-cols-1 col-span-1 items-center mx-auto b-yellow-300">
            <div className="h-12 w-12 b-orange-400">{icon}</div>
          </div>
        </div>
      </>
    );
  };
  const ServiceItem = ({
    title,
    description,
    icon,
  }: {
    title: string;
    description: string;
    icon: ReactNode;
  }) => {
    return (
      <div className="p-6 grid grid-cols-6 bg-gray-200 rounded-lg shadow-md">
        <div className="grid grid-cols-6 col-span-6 items-center">
          <h5 className="col-span-5 text-2xl w-[90%] font-bold text-gray-900 underline decoration-[#ff5c01]">
            {title}
          </h5>
          <div className="col-span-1 h-10 w-10">{icon}</div>
        </div>
        <div className="col-span-6">
          <p className="font-light pt-4">{description}</p>
        </div>
      </div>
    );
  };
  return (
    <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff] overflow-x-hidden lg:-mt-10">
      {/* Hero Section */}
      <div className="text-center px-4 flex flex-col space-y-4">
        <h1 className="text-4xl font-semibold underline decoration-[#ff5c01]">
          Our Services
        </h1>
        <p className="font-light text-xl">
          Whether it's large-scale custom software development, professional
          UI/UX design, or 24/7 server support, we have the resources to meet
          your specific needs.
        </p>
      </div>

      {/* Services Cards */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-5 md:px-20 px-10 py-20">
        {/* Replace with actual card components */}
        <ServiceItem
          title="Web Applications"
          description="Full-Stack web application development. From an idea to a fully deployed and functioning web app, we facilitate the entire process while keeping you informed along the way"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
              viewBox="0 0 24 24"
            >
              <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z" />
            </svg>
          }
        />
        <ServiceItem
          title="Websites"
          description="Magnificent landing pages for your business. Enhance your online presence with our industry standard technology to make your website stand out. Exceptional performance and Search Engine Optimization is guaranteed, we even have the statistics to prove it."
          icon={
            <svg
              viewBox="0 0 24 24"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="页面-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="Development"
                  transform="translate(-96.000000, 0.000000)"
                  fill-rule="nonzero"
                >
                  <g id="web_line" transform="translate(96.000000, 0.000000)">
                    <path
                      d="M24,0 L24,24 L0,24 L0,0 L24,0 Z M12.5934901,23.257841 L12.5819402,23.2595131 L12.5108777,23.2950439 L12.4918791,23.2987469 L12.4918791,23.2987469 L12.4767152,23.2950439 L12.4056548,23.2595131 C12.3958229,23.2563662 12.3870493,23.2590235 12.3821421,23.2649074 L12.3780323,23.275831 L12.360941,23.7031097 L12.3658947,23.7234994 L12.3769048,23.7357139 L12.4804777,23.8096931 L12.4953491,23.8136134 L12.4953491,23.8136134 L12.5071152,23.8096931 L12.6106902,23.7357139 L12.6232938,23.7196733 L12.6232938,23.7196733 L12.6266527,23.7031097 L12.609561,23.275831 C12.6075724,23.2657013 12.6010112,23.2592993 12.5934901,23.257841 L12.5934901,23.257841 Z M12.8583906,23.1452862 L12.8445485,23.1473072 L12.6598443,23.2396597 L12.6498822,23.2499052 L12.6498822,23.2499052 L12.6471943,23.2611114 L12.6650943,23.6906389 L12.6699349,23.7034178 L12.6699349,23.7034178 L12.678386,23.7104931 L12.8793402,23.8032389 C12.8914285,23.8068999 12.9022333,23.8029875 12.9078286,23.7952264 L12.9118235,23.7811639 L12.8776777,23.1665331 C12.8752882,23.1545897 12.8674102,23.1470016 12.8583906,23.1452862 L12.8583906,23.1452862 Z M12.1430473,23.1473072 C12.1332178,23.1423925 12.1221763,23.1452606 12.1156365,23.1525954 L12.1099173,23.1665331 L12.0757714,23.7811639 C12.0751323,23.7926639 12.0828099,23.8018602 12.0926481,23.8045676 L12.108256,23.8032389 L12.3092106,23.7104931 L12.3186497,23.7024347 L12.3186497,23.7024347 L12.3225043,23.6906389 L12.340401,23.2611114 L12.337245,23.2485176 L12.337245,23.2485176 L12.3277531,23.2396597 L12.1430473,23.1473072 Z"
                      id="MingCute"
                      fill-rule="nonzero"
                    ></path>
                    <path
                      d="M19,4 C20.1046,4 21,4.89543 21,6 L21,18 C21,19.1046 20.1046,20 19,20 L5,20 C3.89543,20 3,19.1046 3,18 L3,6 C3,4.89543 3.89543,4 5,4 L19,4 Z M19,12 L5,12 L5,18 L19,18 L19,12 Z M19,6 L5,6 L5,10 L19,10 L19,6 Z M7,7 C7.55228,7 8,7.44772 8,8 C8,8.55228 7.55228,9 7,9 C6.44772,9 6,8.55228 6,8 C6,7.44772 6.44772,7 7,7 Z M10,7 C10.5523,7 11,7.44772 11,8 C11,8.55228 10.5523,9 10,9 C9.44772,9 9,8.55228 9,8 C9,7.44772 9.44772,7 10,7 Z M13,7 C13.5523,7 14,7.44772 14,8 C14,8.55228 13.5523,9 13,9 C12.4477,9 12,8.55228 12,8 C12,7.44772 12.4477,7 13,7 Z"
                      id="形状"
                      fill="#09244B"
                    ></path>
                  </g>
                </g>
              </g>
            </svg>
          }
        />
        <ServiceItem
          title="Mobile Applications"
          description="Change the lives of people around the world with cross platform development for both Android and iOS. No idea is too big for our team."
          icon={
            <svg
              viewBox="0 0 17 17"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 0h-8c-0.827 0-1.5 0.673-1.5 1.5v14c0 0.827 0.673 1.5 1.5 1.5h8c0.827 0 1.5-0.673 1.5-1.5v-14c0-0.827-0.673-1.5-1.5-1.5zM4.5 1h8c0.276 0 0.5 0.224 0.5 0.5v1.5h-9v-1.5c0-0.276 0.224-0.5 0.5-0.5zM13 4v8h-9v-8h9zM12.5 16h-8c-0.276 0-0.5-0.224-0.5-0.5v-2.5h9v2.5c0 0.276-0.224 0.5-0.5 0.5zM9 14.5c0 0.276-0.224 0.5-0.5 0.5s-0.5-0.224-0.5-0.5 0.224-0.5 0.5-0.5 0.5 0.224 0.5 0.5z"
                fill="#000000"
              />
            </svg>
          }
        />
        <ServiceItem
          title="Software Maintenance"
          description="No matter the project you have with us, we provide 24/7 maintenance and support. Need updates or changes to your product? By working with us we have you covered."
          icon={
            <svg
              fill="#000000"
              viewBox="0 0 1000 1000"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M556 397q20-64 4.5-130T497 152q-37-38-87-56.5T307 81q-16 1-25.5 12t-10 25.5T282 145q18 17 63 52.5t62 52.5 16.5 39.5T407 328l-79 79q-16 16-39 16.5T251 408L145 283q-12-11-26.5-11T93 281.5 81 307q-5 60 19.5 115.5t72 93T280 564t117-7q9-3 17.5-.5T428 566l242 311q28 30 66.5 41t77.5 1 67-38.5 38-67-1-77.5-41-66L566 428q-7-5-9.5-13.5T556 397zm252 411q-10 11-24.5 13.5t-28.5-3-22-17.5-8-27 8-27.5 22-18 28.5-2.5 24.5 13q14 14 14 34.5T808 808z" />
            </svg>
          }
        />
        <ServiceItem
          title="Customer Support"
          description="Optimizing the way you handle your customers and stay organized. Our efficient solutions allow us to enhance the performance of your business."
          icon={
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 472.615 472.615"
            >
              <g>
                <g>
                  <polygon
                    points="472.615,393.846 472.615,344.583 0,344.583 0,393.846 147.692,393.846 147.692,452.923 108.308,452.923 
             108.308,472.615 364.308,472.615 364.308,452.923 324.923,452.923 324.923,393.846 		"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M236.308,0c-51.628,0-96.826,27.681-121.849,68.923h23.67c22.433-29.844,58.052-49.231,98.179-49.231
             c40.127,0,75.745,19.387,98.179,49.231h23.67C333.134,27.681,287.936,0,236.308,0z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M358.157,68.923c13.059,21.523,20.723,46.672,20.723,73.649v3.938c14.868,9.649,24.812,26.388,24.812,45.489
             c0,16.738-7.68,31.803-19.692,41.748v51.791h-90.388c-4.037,11.422-14.966,19.692-27.766,19.692h-29.538
             c-16.246,0-29.538-13.292-29.538-29.538c0-16.345,13.292-29.538,29.538-29.538h29.538c12.8,0,23.729,8.172,27.766,19.692h70.695
             v-21.761c-4.726,1.38-9.649,2.069-14.769,2.069h-14.769V137.846h14.769c3.249,0,6.498,0.294,9.551,0.886
             c-0.781-26.178-9.88-50.223-24.603-69.809H138.129c-14.723,19.586-23.822,43.631-24.603,69.809
             c3.052-0.592,6.302-0.886,9.551-0.886h14.769v108.308h-14.769c-29.834,0-54.154-24.321-54.154-54.154
             c0-19.102,9.945-35.84,24.812-45.489v-3.938c0-26.977,7.664-52.126,20.723-73.649H0v255.967h472.615V68.923H358.157z"
                  />
                </g>
              </g>
            </svg>
          }
        />
        <ServiceItem
          title="UI/UX Design"
          description="Crafting software solutions that work across all devices is our speciality. We are sure to make your idea stand out to your target audience."
          icon={
            <svg
              version="1.1"
              id="Uploaded to svgrepo.com"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
            >
              <path
                className="stone_een"
                d="M15,10h2v2h-2V10z M6.5,10C6.224,10,6,10.224,6,10.5S6.224,11,6.5,11S7,10.776,7,10.5S6.776,10,6.5,10z
        M21.5,29H20v-2h-8v2h-1.5c-0.276,0-0.5,0.224-0.5,0.5c0,0.276,0.224,0.5,0.5,0.5h11c0.276,0,0.5-0.224,0.5-0.5
       C22,29.224,21.776,29,21.5,29z M32,7v16c0,1.657-1.343,3-3,3H3c-1.657,0-3-1.343-3-3V7c0-1.657,1.343-3,3-3h26
       C30.657,4,32,5.343,32,7z M26.966,10.174c-0.12-0.572-0.587-1.03-1.16-1.144c-0.782-0.154-1.475,0.3-1.713,0.97H18V9h-4v1H7.908
       C7.67,9.33,6.976,8.876,6.195,9.03c-0.573,0.113-1.04,0.572-1.16,1.144C4.83,11.145,5.565,12,6.5,12c0.652,0,1.202-0.419,1.408-1H14
       v0.224c-4.388,0.897-7.745,4.658-7.986,9.246C5.999,20.757,6.227,21,6.515,21h0c0.263,0,0.484-0.204,0.497-0.467
       c0.209-4.065,3.134-7.41,6.988-8.289V13h4v-0.757c3.854,0.88,6.779,4.225,6.988,8.289C25.002,20.796,25.222,21,25.485,21h0
       c0.288,0,0.516-0.243,0.501-0.53c-0.241-4.588-3.598-8.349-7.986-9.246V11h6.092c0.207,0.581,0.756,1,1.408,1
       C26.435,12,27.17,11.145,26.966,10.174z M25.5,10c-0.276,0-0.5,0.224-0.5,0.5s0.224,0.5,0.5,0.5s0.5-0.224,0.5-0.5
       S25.776,10,25.5,10z"
              />
            </svg>
          }
        />

        {/* Repeat for other services */}
      </div>

      {/* Horizontally Scrollable Services Section */}
      {/* Services with images, titles, descriptions, "Learn More" buttons */}
      {/* Example Service */}
      {/* Image Here */}
      {/* <div className="flex overflow-x-auto p-4 space-x-4">
        <div className="flex-none w-60 h-48 bg-gray-200 rounded-lg p-4">
          <h5 className="text-lg font-bold">Web Applications</h5>
          <p className="text-sm">Service description...</p>
          <button className="mt-4 bg-[#ff5c01] text-white px-3 py-1 rounded">
            Learn More
          </button>
        </div>
      </div> */}
      {/* Repeat for other services */}
      <div className="grid grid-rows-2 bg-gradient-to-r from-[#d0d0d0] to-[#d0d0d0] via-[#f9f9f9] h-48 py-12">
        <div className="flex justify-center items-start">
          <p className="md:text-5xl text-4xl font-bold text-black">
            We make it{" "}
            <span className="underline decoration-[#ff5c01]">work.</span>
          </p>
        </div>
        <div className="flex justify-center justify-end items-end">
          <p className="text-3xl font-semibold text-black">
            With <span className="underline decoration-[#ff5c01]">5</span> Key
            Steps.
          </p>
        </div>
      </div>
      {/* The Essential Process Section */}
      <div className="pb-10 pt-20 px-4">
        <h2 className="text-3xl font-bold text-center mb-6">
          The Essential Process
        </h2>
        <hr className="border-[#ff5c01] mb-6" />
        <div className="grid lg:grid-cols-3 gap-5 md:px-16 px-5">
          {/* Example Process Step */}
          {/* <div className="bg-gray-200 rounded-lg p-4 shadow-md">
            <h5 className="text-lg font-bold">Planning & Understanding</h5>
            <p className="font-light">n</p>
          </div> */}
          <ProcessStep
            title="Planning & Understanding"
            text="Let's understand your situation"
            icon={
              <>
                <svg
                  version="1.2"
                  baseProfile="tiny"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 256 256"
                >
                  <path
                    id="XMLID_1_"
                    d="M177.5,74.9c-1.7,7.7-3.8,28.4-3.8,28.4l-19.6,49.9c-0.5,0.8-0.8,1.9-0.8,3.1c0,4.3,3.5,7.8,7.7,7.8H173v26.3
	c0,20.7,17.5,37.7,38.1,37.7H226v28h30V7.9C218,13.4,185,42.7,177.5,74.9z M196.9,122.2c-5.7,0-10.5-4.8-10.5-10.5
	c0-5.7,4.8-10.5,10.5-10.5s10.5,4.8,10.5,10.5C207.4,117.4,202.7,122.2,196.9,122.2z M82.6,104.1c0,0-2.2-20.7-3.9-28.4
	C71.2,43.5,38,14.2,0,8.7V257h30v-28h15.2c20.7,0,37.8-17.2,37.8-37.9V165h12.3c4.3,0,7.7-3.6,7.7-7.9c0-1.2-0.3-2.4-0.8-3.1
	L82.6,104.1z M59.4,123c-5.8,0-10.5-4.8-10.5-10.5c0-5.7,4.8-10.5,10.5-10.5s10.5,4.8,10.5,10.5C69.9,118.2,65.1,123,59.4,123z
	 M177.9,26.9c1.8-2.8,5.5-3.6,8.3-1.8c2.8,1.8,3.6,5.5,1.8,8.3c-1.2,2-3.4,2.9-5.6,2.7c-0.9-0.1-1.8-0.4-2.7-0.9
	C176.9,33.4,176.1,29.7,177.9,26.9z M64.7,35.3c-1.9-2.7-1.2-6.5,1.5-8.3c2.7-1.9,6.5-1.2,8.3,1.6c1.9,2.7,1.2,6.5-1.5,8.3
	c-1.2,0.8-2.6,1.2-4,1C67.4,37.7,65.8,36.8,64.7,35.3z M90.8,20.6c-1-3.2,0.7-6.5,3.9-7.5c3.1-1,6.5,0.7,7.5,3.9
	c1,3.2-0.7,6.5-3.9,7.5c-0.8,0.3-1.6,0.3-2.4,0.3C93.6,24.5,91.6,23,90.8,20.6z M155.1,23.8c-0.4,0-0.7-0.1-1.1-0.2
	c-3.2-0.9-5-4.2-4.1-7.4c0.9-3.2,4.2-5,7.4-4.1c3.2,0.9,5,4.2,4.1,7.4C160.6,22.3,157.9,24.1,155.1,23.8z M132,13.9
	c0,3.3-2.6,6-5.9,6.1c-0.2,0-0.4,0-0.7,0c-3-0.3-5.4-2.8-5.4-5.9c-0.1-3.3,2.6-6,5.9-6.1C129.3,7.9,132,10.6,132,13.9z"
                  />
                </svg>
              </>
            }
          />
          <ProcessStep
            title="Design & Code"
            text="Let's build you a prototype"
            icon={
              <>
                <svg
                  fill="#000000"
                  // width="20px"
                  // height="20px"
                  viewBox="0 0 36 36"
                  version="1.1"
                  preserveAspectRatio="xMidYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>design-solid</title>
                  <path d="M34.87,32.21,30,27.37V8.75L27.7,4.52a2,2,0,0,0-3.54,0L22,8.76V19.41L3.71,1.21A1,1,0,0,0,2,1.92V10H4.17v1.6H2V18H4.17v1.6H2v6.65H4.17v1.6H2v5.07a1,1,0,0,0,1,1H34.16a1,1,0,0,0,.71-1.71ZM10,26V16.94L19.07,26Zm18,2.11H24V25.68h4Zm0-4H24V9.25l1.94-3.77L28,9.26Z"></path>
                  <rect x="0" y="0" width="36" height="36" fill-opacity="0" />
                </svg>
              </>
            }
          />
          <ProcessStep
            title="Your Feedback"
            text="Your input matters to us"
            icon={
              <>
                <svg
                  fill="#000000"
                  version="1.1"
                  baseProfile="tiny"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 42 42"
                >
                  <path
                    d="M6.5,25.5v4c0.016,2.812,1.344,2.375,2.328,1.531L14.5,25.91v2.59c0,2.43,0.56,3,3,3h9c0,0,5.209,6.125,5.25,6.084
	c0.75,0.916,2.781,0.604,2.75-1.084v-5h3c2.45,0,3-0.609,3-3v-15c0-2.4-0.59-3-3-3h-10v-2c0-2.47-0.46-3-3-3h-21c-2.36,0-3,0.51-3,3
	v13c0,2.439,0.55,4,3,4H6.5z M31.5,28.5v4.721l-4-4.721h-9c-0.75,0-1-0.27-1-1v-13c0-0.67,0.31-1,1-1h18c0.689,0,1,0.37,1,0.94V27.5
	c0,0.721-0.359,1-1,1H31.5z"
                  />
                </svg>
              </>
            }
          />
          <ProcessStep
            title="Testing & Deployment"
            text="Reinforce reliability and make it go live"
            icon={
              <div className="scale-110 mt-3 ml-2">
                <svg
                  fill="#000000"
                  version="1.1"
                  preserveAspectRatio="xMidYMid meet"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    className="clr-i-solid clr-i-solid-path-1"
                    d="M33,2H22.1a1,1,0,0,0,0,2h8.53l-8.82,9a1,1,0,1,0,1.43,1.4L32,5.46V13.9a1,1,0,0,0,2,0V3A1,1,0,0,0,33,2Z"
                  ></path>
                  <path
                    className="clr-i-solid clr-i-solid-path-2"
                    d="M12.46,10.73a1,1,0,0,0-1,0l-8.68,5L12,21l9.19-5.26Z"
                  ></path>
                  <path
                    className="clr-i-solid clr-i-solid-path-3"
                    d="M2,27.73a1,1,0,0,0,.5.87L11,33.46v-11L2,17.28Z"
                  ></path>
                  <path
                    className="clr-i-solid clr-i-solid-path-4"
                    d="M13,33.46l8.5-4.86a1,1,0,0,0,.5-.87V17.29l-9,5.15Z"
                  ></path>
                </svg>
              </div>
            }
          />
          <ProcessStep
            title="Ongoing Support"
            text="We're available 24/7"
            icon={
              <>
                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <rect width="24" height="24" fill="none" />
                  <path d="M12,2a8,8,0,0,0-8,8v1.9A2.92,2.92,0,0,0,3,14a2.88,2.88,0,0,0,1.94,2.61C6.24,19.72,8.85,22,12,22h3V20H12c-2.26,0-4.31-1.7-5.34-4.39l-.21-.55L5.86,15A1,1,0,0,1,5,14a1,1,0,0,1,.5-.86l.5-.29V11a1,1,0,0,1,1-1H17a1,1,0,0,1,1,1v5H13.91a1.5,1.5,0,1,0-1.52,2H20a2,2,0,0,0,2-2V14a2,2,0,0,0-2-2V10A8,8,0,0,0,12,2Z" />
                </svg>
              </>
            }
          />

          {/* Repeat for other process steps */}
        </div>
      </div>

      {/* Call to Action Section */}
      <div className="text-center py-10 px-4">
        <h2 className="text-3xl font-bold mb-3">Ready to Start?</h2>
        <a href="/contact">
          <button className="bg-[#ff5c01] text-white text-2xl px-6 py-2 rounded-lg font-bold hover:scale-110 transition ease-in-out">
            Contact Us
          </button>
        </a>
      </div>

      {/* QA Section */}
      {/* <div className="py-10 px-4">
        <h2 className="text-3xl font-bold text-center mb-6">
          Got a Question? We have the answer.
        </h2>
      </div> */}
      {/* Collapsible QA components */}

      {/* Additional Contact and Information Sections */}
      <div className="py-10 px-4 bg-black">
        <h2 className="text-3xl font-bold text-center text-white mb-3">
          Technical Experts
        </h2>
        <p className="text-center text-white">We are expert problem solvers.</p>
      </div>
    </div>
  );
};

export default ServicesPage;
