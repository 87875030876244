import React from "react";

type Props = {};

const TermsPage = () => {
  return (
    <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff]">
      <div className="px-20 pb-10">
        <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>

        <p>
          Welcome to TechTangelo! These terms of service ("Terms") govern your
          use of our website, products, and services provided by TechTangelo
          ("we," "us," or "our"). By accessing or using our website, products,
          or services, you agree to be bound by these Terms. If you do not agree
          to these Terms, please do not access or use our website, products, or
          services.
        </p>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">1. Services</h2>
          <ul className="list-disc ml-8">
            <li>
              <strong>Website development:</strong> We design and develop custom
              websites using the latest technologies to enhance user experience
              and functionality.
            </li>
            <li>
              <strong>Web application development:</strong> We create web
              applications that are scalable, secure, and optimized for
              performance to help you achieve your business goals.
            </li>
            <li>
              <strong>Mobile application development:</strong> We build native
              and cross-platform mobile applications that run seamlessly on iOS
              and Android devices, providing a unique user experience.
            </li>
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">2. Acceptable Use</h2>
          <p>
            You agree to use our website, products, and services in accordance
            with these Terms and applicable laws and regulations. You may not:
          </p>
          <ul className="list-disc ml-8">
            <li>
              Use our website, products, or services for any unlawful purpose or
              to engage in any illegal activity.
            </li>
            <li>
              Violate the rights of others, including intellectual property
              rights or privacy rights.
            </li>
            <li>
              Attempt to interfere with the operation of our website, products,
              or services, including by hacking, cracking, or disrupting our
              systems.
            </li>
            <li>
              Use our website, products, or services to transmit viruses,
              malware, or any other harmful code.
            </li>
            <li>
              Engage in any activity that could damage, disable, or impair our
              website, products, or services.
            </li>
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">3. Intellectual Property</h2>
          <p>
            All content, materials, and intellectual property displayed on our
            website or provided as part of our services, including but not
            limited to text, graphics, logos, images, and software, are owned by
            TechTangelo or its licensors and are protected by copyright,
            trademark, and other laws. You may not reproduce, distribute,
            modify, or create derivative works of any content without our prior
            written consent.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">4. Privacy Policy</h2>
          <p>
            Our Privacy Policy governs the collection, use, and disclosure of
            your personal information when you use our website, products, or
            services. Please review our Privacy Policy to understand how we
            handle your personal information.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">
            5. Disclaimer of Warranties
          </h2>
          <p>
            Our website, products, and services are provided on an "as is" and
            "as available" basis without any warranties of any kind, express or
            implied. We do not warrant that our website, products, or services
            will be uninterrupted, error-free, or free from harmful components.
            You use our website, products, and services at your own risk.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">6. Limitation of Liability</h2>
          <p>
            To the fullest extent permitted by law, TechTangelo shall not be
            liable for any direct, indirect, incidental, special, consequential,
            or punitive damages arising out of or in connection with your use of
            our website, products, or services, including but not limited to
            loss of profits, data, or goodwill.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">7. Changes to Terms</h2>
          <p>
            We reserve the right to update or modify these Terms at any time
            without prior notice. Any changes to these Terms will be effective
            immediately upon posting. Your continued use of our website,
            products, or services after the posting of revised Terms constitutes
            your acceptance of the changes.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">8. Contact Us</h2>
          <p>
            If you have any questions or concerns about these Terms or our
            services, please contact us at{" "}
            <a href="mailto:contact@techtangelo.com">contact@techtangelo.com</a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
