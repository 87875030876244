// BannerAnimation.tsx
import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';

type Segment = {
  text: string;
  isStyled: boolean;
};

type BannerAnimationProps = {
  segments: Segment[];
  className: string;
};

const BannerAnimation: React.FC<BannerAnimationProps> = ({ segments, className }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentSegmentIndex, setCurrentSegmentIndex] = useState(0);
  const [currentCharIndex, setCurrentCharIndex] = useState(0);
  const [showCursor, setShowCursor] = useState(true);

  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setShowCursor(prev => !prev);
    }, 500);

    return () => clearInterval(cursorInterval);
  }, []);

  useEffect(() => {
    if (currentSegmentIndex < segments.length) {
      const currentSegment = segments[currentSegmentIndex].text;

      if (currentCharIndex < currentSegment.length) {
        setTimeout(() => {
          setCurrentText(currentText + currentSegment.charAt(currentCharIndex));
          setCurrentCharIndex(currentCharIndex + 1);
        }, 50);
      } else {
        setTimeout(() => {
          setCurrentSegmentIndex(currentSegmentIndex + 1);
          setCurrentCharIndex(0);
          if (currentSegmentIndex < segments.length - 1) {
            setCurrentText(currentText + ' ');
          }
        }, 50);
      }
    }
  }, [currentSegmentIndex, currentCharIndex, segments, currentText]);

  const renderText = () => {
    let accumulatedLength = 0;
    return segments.map((segment, index) => {
      const start = accumulatedLength;
      const end = start + segment.text.length;
      accumulatedLength += segment.text.length + (index < segments.length - 1 ? 1 : 0);

      const segmentText = currentText.slice(start, end);
      return (
        <span key={index} className={segment.isStyled ? 'text-[#ff5c01]' : ''}>
          {segmentText}
        </span>
      );
    });
  };

  return (
    <motion.div className={className} style={{ position: 'relative' }}>
      {renderText()}
      <div
        className={`${showCursor ? 'opacity-100' : 'opacity-0'}`}
        style={{
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '0.8em',
          width: '12px',
          backgroundColor: 'currentColor',
          marginLeft: '2px',
        }}
      />
    </motion.div>
  );
};

export default BannerAnimation;
