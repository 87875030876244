import React, { useState, useRef, useEffect } from "react";
import ContactModal from "./ContactModal";

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);

  // Close menu if clicked outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [menuRef]);

  return (
    <header className="py-4 bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff] overflow-x-hidden pb-10 md:pb-0">
      <div className="flex flex-row justify-between md:px-10 px-8 py-2 md:items-start items-center w-full">
        <a href="/">
          <img src="/tangelologo.png" className="h-20" />
        </a>

        <div className="">
          <div className="flex items-center md:space-x-5">
            <button
              onClick={() => setContactModalOpen(true)}
              className="bg-black hover:bg-[#ff5c01] hover:scale-110 active:scale-90 transition ease-in-out text-white font-bold py-2 px-4 rounded md:block hidden"
            >
              Start Project
            </button>
            <button
              onClick={() => setMenuOpen(!isMenuOpen)}
              className="bg-black hover:bg-[#ff5c01] hover:scale-110 active:scale-90 transition ease-in-out text-white shadow-md font-bold p-2.5 rounded"
            >
              <svg
                fill="#ffffff"
                width="20px"
                height="20px"
                viewBox="0 0 512 512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>menu</title>
                <path d="M64 96L448 96 448 144 64 144 64 96ZM64 232L448 232 448 280 64 280 64 232ZM64 368L448 368 448 416 64 416 64 368Z" />
              </svg>
            </button>
          </div>
        </div>

        {isContactModalOpen && (
          <ContactModal
            isOpen={isContactModalOpen}
            onClose={() => setContactModalOpen(false)}
          />
        )}
      </div>

      <div className="flex flex-row justify-center pt-5">
        <button
          onClick={() => setContactModalOpen(true)}
          className="bg-black hover:bg-[#ff5c01] hover:scale-110 active:scale-90 transition ease-in-out text-white font-bold py-2 px-4 rounded md:hidden block md:text-xl text-2xl"
        >
          Start Project
        </button>
      </div>
      {isMenuOpen && (
        <div
          ref={menuRef}
          className="fixed top-0 right-0 w-[400px] h-full bg-black shadow-lg p-4 z-50"
          style={{
            animation: isMenuOpen ? "slideInFromRight 0.2s ease forwards" : "",
          }}
        >
          <style>
            {`
              @keyframes slideInFromRight {
                from {
                  transform: translateX(100%);
                  opacity: 0;
                }
                to {
                  transform: translateX(0);
                  opacity: 1;
                }
              }
            `}
          </style>
          <button
            onClick={() => setMenuOpen(false)}
            className="text-white text-2xl font-bold hover:text-[#ff5c01] focus:outline-none"
          >
            &times;
          </button>
          {/* Navigation Links and Content */}
          <nav className="mt-5 flex flex-col items-end py-10">
            <ul className="space-y-8">
              <li className="flex justify-end">
                <a
                  href="/"
                  className="text-white hover:text-[#ff5c01] font-bold text-3xl"
                >
                  Home
                </a>
              </li>
              <li className="flex justify-end">
                <a
                  href="/portfolio"
                  className="text-white hover:text-[#ff5c01] font-bold text-3xl"
                >
                  Portfolio
                </a>
              </li>
              <li className="flex justify-end">
                <a
                  href="/services"
                  className="text-white hover:text-[#ff5c01] font-bold text-3xl"
                >
                  Services
                </a>
              </li>
              <li className="flex justify-end">
                <a
                  href="/contact"
                  className="text-white hover:text-[#ff5c01] font-bold text-3xl"
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>

          {/* separating line */}
          <div className="border-t border-white w-full my-10"></div>

          {/* Featured Project and Get In Touch */}
          <div className="flex items-start justify-center mt-10">
            {/* Featured Project Section */}
            {/* <div className="w-1/2">
              <h3 className="font-bold text-white">Featured Project</h3>
            </div> */}
            {/* Content for Featured Project */}

            {/* Contact Information */}
            <div className="w-full flex flex-col items-center">
              <h3 className="font-bold text-white">Get In Touch</h3>
              <p className="text-white">Email: team@techtangelo.com</p>
              <p className="text-white">Phone 1: +1 (647) 462-3858</p>
              <p className="text-white">Phone 2: +1 (416) 670-2339</p>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
