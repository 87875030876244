// Function to validate an email address
export const validateEmail = (email: string): boolean => {
  return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);
};

// Function to validate a name
export const validateName = (name: string): boolean => {
  // Check if the name is not just whitespace and matches the criteria
  return name.trim().length >= 3 && name.trim().length <= 50 && /^[a-zA-Z ]+$/.test(name.trim());
};

// Function to validate a message
export const validateMessage = (message: string): boolean => {
  // Check if the message is not just whitespace and within length range
  return message.trim().length >= 10 && message.trim().length <= 800;
};
