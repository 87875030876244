import React from "react";

const ServiceItem = ({ text }: { text: string }) => {
  return (
    <>
      <div className="border-[#ff5c01] border-4 rounded-lg md:px-4 px:2 py-2 flex justify-center items-center">
        <p className="text-white text-xl text-center font-semibold p-1">
          {text}
        </p>
      </div>
    </>
  );
};

export default ServiceItem;
