import React from "react";

type Props = {};

const PortfolioPage = (props: Props) => {
  const PortfolioItem = ({
    name,
    description,
    image,
  }: {
    name: string;
    description: string;
    image: string;
  }) => {
    return (
      <>
        <div className="bg-white shadow-md rounded-lg flex flex-col md:w-[550px] w-full p-5 items-center space-y-5 hover:scale-105 transition ease-in-out">
          <img src={image} className="object-cover h-64 w-auto shadow-lg" />
          <h1 className="text-2xl font-bold underline decoration-[#ff5c01]">
            {name}
          </h1>
          <p className="font-light">{description}</p>
        </div>
      </>
    );
  };

  const PortfolioItems = [
    {
      name: "Stoneville Landscaping",
      description: "Landscaping designers, builders, and maintainers",
      image: "/stoneville.png",
    },
    {
      name: "DMCC Holdings",
      description: "Real Estate Investment across North America",
      image: "/dmcc.png",
    },
    {
      name: "The Maxx Custom",
      description: "Custom embroidery for garmets and jerseys",
      image: "/maxxcustom.png",
    },
    {
      name: "Furious Fades",
      description: "High-end barbershop for high profile clients",
      image: "/furiousfades.png",
    },
    {
      name: "RideCaller",
      description: "Mobile marketplace for limo & chauffeur services",
      image: "/ridecaller.png",
    },
  ];

  return (
    <>
      <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff] flex flex-col justify-center items-center">
        <div className="grid md:grid-cols-2 grid-cols-1 md:px-20 px-10 gap-8">
          {PortfolioItems.map((item, i) => (
            <PortfolioItem
              name={item.name}
              description={item.description}
              image={item.image}
              key={i}
            />
          ))}
        </div>
      </div>
      <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff] py-10 md:px-32 px-5">
        <div>
          <p className="text-5xl font-bold">
            Join our list of{" "}
            <span className="decoration-[#ff5c01] underline">
              successful projects
            </span>
            .
          </p>
        </div>
        <div className="text-right text-5xl font-bold">Contact us today.</div>
      </div>
    </>
  );
};

export default PortfolioPage;
