import React from "react";

type Props = {};

const PrivacyPage = () => {
  return (
    <div className="bg-gradient-to-r from-[#e7e7e7] to-[#e7e7e7] via-[#ffffff]">
      <div className="px-20 pb-10">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

        <p>
          Welcome to TechTangelo! This Privacy Policy describes how we collect,
          use, and disclose your personal information when you use our website,
          products, or services provided by TechTangelo ("we," "us," or "our").
          By accessing or using our website, products, or services, you consent
          to the collection, use, and disclosure of your personal information in
          accordance with this Privacy Policy.
        </p>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">1. Information We Collect</h2>
          <p>
            We may collect personal information from you when you interact with
            our website, products, or services, including but not limited to:
          </p>
          <ul className="list-disc ml-8">
            <li>Information you provide when you fill out forms or surveys.</li>
            <li>
              Information about your device and how you interact with our
              website or services, such as your IP address, browser type, and
              operating system.
            </li>
            <li>
              Information collected through cookies and similar technologies
              when you use our website or services.
            </li>
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">
            2. How We Use Your Information
          </h2>
          <p>
            We may use your personal information for various purposes, including
            but not limited to:
          </p>
          <ul className="list-disc ml-8">
            <li>
              Providing and maintaining our website, products, and services.
            </li>
            <li>Communicating with you about your account or transactions.</li>
            <li>Personalizing your experience on our website or services.</li>
            <li>
              Analyzing usage data to improve our website, products, and
              services.
            </li>
            <li>Sending you marketing communications.</li>
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">3. Information Sharing</h2>
          <p>
            We may share your personal information with third parties in the
            following circumstances:
          </p>
          <ul className="list-disc ml-8">
            <li>With your consent or at your direction.</li>
            <li>
              With service providers or partners who assist us in providing our
              website, products, or services.
            </li>
            <li>
              When required by law or to protect our rights, property, or
              safety, or the rights, property, or safety of others.
            </li>
            <li>
              In connection with a merger, acquisition, or sale of assets.
            </li>
          </ul>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">4. Your Choices</h2>
          <p>
            You have the right to access, update, or delete your personal
            information. You may also opt-out of receiving marketing
            communications from us. Please contact us if you would like to
            exercise any of these rights.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">
            5. Changes to this Privacy Policy
          </h2>
          <p>
            We reserve the right to update or modify this Privacy Policy at any
            time without prior notice. Any changes to this Privacy Policy will
            be effective immediately upon posting. Your continued use of our
            website, products, or services after the posting of revised Privacy
            Policy constitutes your acceptance of the changes.
          </p>
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-bold mb-4">6. Contact Us</h2>
          <p>
            If you have any questions or concerns about this Privacy Policy or
            our privacy practices, please contact us at{" "}
            <a href="mailto:privacy@techtangelo.com">privacy@techtangelo.com</a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
