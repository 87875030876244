import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import SuccessModal from "./SuccessModal";
import {
  validateEmail,
  validateMessage,
  validateName,
} from "../utils/ValidateInput";
import { API_ENDPOINTS } from "../environment/api_endpoints";

const ContactModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Reset errors
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    let isValid = true;

    // Validate name
    if (!validateName(name)) {
      setNameError(true);
      isValid = false;
    }

    // Validate email
    if (!validateEmail(email)) {
      setEmailError(true);
      isValid = false;
    }

    // Validate message
    if (!validateMessage(message)) {
      setMessageError(true);
      isValid = false;
    }

    if (!isValid) {
      setLoading(false);
      return;
    } else {
      setLoading(true);
    }

    // If all is valid, send the form data
    try {
      await axios.post(API_ENDPOINTS.SEND_EMAIL, { name, email, message });
      setIsSuccessModalOpen(true);
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle server error response here
    } finally {
      setLoading(false);
    }
  };

  const handleNameChange = (text: string) => {
    setName(text);
  };

  const handleEmailChange = (text: string) => {
    setEmail(text);
  };

  const handleMessageChange = (text: string) => {
    setMessage(text);
  };

  const modalRef = useRef<HTMLDivElement | null>(null);

  // Disable scrolling when modal is open
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    if (isOpen) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = originalStyle;
    };
  }, [isOpen]);

  // Close modal if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const onSuccessClose = () => {
    setIsSuccessModalOpen(false);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="absolute inset-0 bg-black bg-opacity-70 overflow-hidden h-full w-full z-50 flex items-center justify-center pr-0"
      onClick={onClose}
    >
      <div
        ref={modalRef}
        className="bg-black p-5 w-5/6 md:w-[600px] shadow-lg rounded-md"
        style={{
          animation: isOpen ? "slideIn 0.5s ease forwards" : "",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <style>
          {`
            @keyframes slideIn {
              from {
                transform: translateY(100px);
                opacity: 0;
              }
              to {
                transform: translateY(0);
                opacity: 1;
              }
            }
          `}
        </style>
        <div className="flex flex-row justify-between items-center">
          <p className="text-2xl font-bold mb-4 text-white">Are you ready?</p>
          <button
            className="bg-white h-8 w-8 absolute top-2 right-2 text-2xl font-bold hover:text-gray-500 rounded-md flex justify-center items-center"
            onClick={onClose}
          >
            <p className="pb-1">&times;</p>
          </button>
        </div>
        <h2 className="text-2xl font-bold mb-4 text-white">
          Let's create&nbsp;
          <span className="text-white underline decoration-[#ff5c01]">
            together.
          </span>
        </h2>
        <p className="text-md mb-4 text-white md:block hidden">
          Whether you're envisioning a fresh project or aiming to enhance your
          brand's digital footprint, we're at your service. Share your details,
          and let's embark on this creative journey together. We'll connect with
          you soon.
        </p>
        <p className="text-md mb-4 text-white md:hidden">
          We're at your service. Share your details, and let's embark on this
          creative journey together. We'll connect with you soon.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="name"
              className="block text-sm font-bold mb-2 text-white"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              className="w-full p-2 border rounded"
            />
            {nameError && (
              <p className="font-bold text-red-500">
                Ensure you entered a valid name
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-bold mb-2 text-white"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => handleEmailChange(e.target.value)}
              className="w-full p-2 border rounded"
            />
            {emailError && (
              <p className="font-bold text-red-500">
                Ensure you entered a valid email
              </p>
            )}
          </div>
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-sm font-bold mb-2 text-white"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={message}
              onChange={(e) => handleMessageChange(e.target.value)}
              className="w-full p-2 border rounded"
              rows={4}
              style={{ resize: "none", overflowY: "auto" }}
            ></textarea>
            {messageError && (
              <p className="font-bold text-red-500">
                Ensure you entered a valid message
              </p>
            )}
          </div>
          <button
            type="submit"
            className="bg-white hover:bg-[#ff5c01] text-black hover:text-white hover:scale-110 focus:scale-90 transition ease-in-out px-4 py-2 rounded font-bold justify-center"
          >
            {loading ? "Sending..." : "Send"}
          </button>
        </form>
      </div>
      {isSuccessModalOpen && (
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => onSuccessClose()}
        />
      )}
    </div>
  );
};

export default ContactModal;
