import React from "react";

const Footer = () => {
  return (
    <footer className="bg-black text-white flex justify-center py-4">
      <div className="px-0 flex flex-col items-center space-y-5">
        <div className="flex flex-col space-y-2">
          <h4 className="font-bold text-lg text-center">Contact Us</h4>
          <p className="font-bold">Email: contact@techtangelo.com</p>
          <p className="font-bold">Phone 1: +1 (416) 670 2339</p>
          <p className="font-bold">Phone 2: +1 (647) 462-3858</p>
        </div>

        <div className="flex flex-row space-x-4 md:space-x-8 lg:space-x-10">
          <a href="/terms" className="hover:text-gray-300">
            Terms of Service
          </a>
          <a href="/privacy" className="hover:text-gray-300">
            Privacy Policy
          </a>
          <a href="/copyright" className="hover:text-gray-300">
            Copyright
          </a>
        </div>

        <div className="text-gray-500">
          © {new Date().getFullYear()} TechTangelo. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
